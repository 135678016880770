import { FC, PropsWithChildren } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useEnumsData from 'src/hooks/data/use-enums-data'
import { AsyncSingleSearchWithSelect } from '~/core/ui/AsyncSingleSearchWithSelect'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { IconButton } from '~/core/ui/IconButton'
import IconWrapper from '~/core/ui/IconWrapper'
import { Input } from '~/core/ui/Input'
import { InputRightElement } from '~/core/ui/InputElement'
import { InputGroup } from '~/core/ui/InputGroup'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { ISelectOption } from '~/core/ui/Select'
import { SingleDatePicker } from '~/core/ui/SingleDatePicker'
import { TextButton } from '~/core/ui/TextButton'
import { Tooltip } from '~/core/ui/Tooltip'
import { changeTimezone } from '~/lib/features/calendar/utilities/helper-schedule-interview'
import useBoundStore from '~/lib/store'
import usePlacementForm from '../hooks/use-placement-form'
import { createEditPlacementSchema, ICreateEditPlacement } from '../schema'
import ComputeInputRightPadding from './ComputeInputRightPadding'

const makeLimitChange =
  (onChange: (value: string | number) => void, floatDigitLength: number = 2) =>
  (value: string | number) => {
    if (value === '') {
      onChange(value)
    }
    if (
      value &&
      !!value
        .toString()
        .match(new RegExp(`^[0-9]\\d*([,.](\\d{1,${floatDigitLength}})?)?$`))
    ) {
      onChange(value)
    } else onChange('')
  }
const onlyPositiveNumberChange =
  (onChange: (value: string | number) => void) => (value: string | number) => {
    if (value === '') {
      onChange(value)
    }

    if (
      value &&
      !!value.toString().match(new RegExp(`^[0-9]\\d*([,.](\\d*)?)?$`))
    ) {
      onChange(value)
    } else onChange('')
  }

const PlacementForm: FC<
  PropsWithChildren<{
    defaultValue: ICreateEditPlacement
    applicant?: {
      hiredDate?: Date
      createdAt?: Date | string
    }
    onSubmit?: (data: ICreateEditPlacement) => Promise<void>
    hiddeFields?: Array<keyof ICreateEditPlacement>
  }>
> = ({ defaultValue, children, onSubmit, hiddeFields = [], applicant }) => {
  const { t, i18n } = useTranslation()
  const {
    fetchHiringMembers,
    placementTypeOfFees,
    typesOfSalary,
    typeOfStatus
  } = usePlacementForm()
  const placementCurrencyOfRevenue = useEnumsData({
    enumType: 'PlacementCurrencyOfRevenue',
    locale: i18n.language
  })

  const { user } = useBoundStore()
  return (
    <DynamicImportForm
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      mode="onSubmit"
      schema={createEditPlacementSchema(t)}>
      {({ formState, control, getValues }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const onboardDateWatcher = useWatch({
          control,
          name: 'onboardDate'
        })
        return (
          <>
            {!hiddeFields?.includes('status') ? (
              <div className="mb-4">
                <FormControlItem
                  destructive={!!formState.errors.status}
                  destructiveText={formState.errors.status?.message}
                  mode="vertical"
                  labelRequired
                  label={`${t(
                    'placements:management:table:placement_status'
                  )}`}>
                  <Controller
                    control={control}
                    name="status"
                    render={({
                      field: { onChange, value },
                      formState: { errors }
                    }) => {
                      return (
                        <NativeSelect
                          type="dot-leading"
                          size="sm"
                          isClearable={false}
                          isSearchable={false}
                          onChange={(newValue) => {
                            onChange(newValue)
                          }}
                          value={value}
                          configSelectOption={{ dot: true }}
                          options={typeOfStatus}
                          menuPosition="fixed"
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                        />
                      )
                    }}
                  />
                </FormControlItem>
              </div>
            ) : null}

            <div>
              <FormControlItem
                destructive={!!formState.errors.hiredDate}
                destructiveText={formState.errors.hiredDate?.message}
                mode="vertical"
                labelRequired
                label={`${t('placements:management:filter:hire_date')}`}>
                <Controller
                  control={control}
                  name="hiredDate"
                  render={({
                    field: { onChange, value },
                    formState: { errors }
                  }) => {
                    return (
                      <SingleDatePicker
                        locale={i18n.language}
                        className="w-full"
                        destructive={!!formState.errors.hiredDate}
                        config={{
                          id: 5,
                          defaultOpen: false,
                          onChange,
                          value: value,
                          showClearIndicator: false,
                          disabled: {
                            before:
                              applicant?.createdAt &&
                              changeTimezone({
                                date: applicant.createdAt,
                                timezone: user?.timezone
                              }),
                            after: new Date()
                          } as any
                        }}
                        placeholder={`${t('label:placeholder:selectDate')}`}
                        size="sm"
                      />
                    )
                  }}
                />
              </FormControlItem>
            </div>
            <div className="mt-4 flex space-x-4">
              <div className="flex-1">
                <FormControlItem
                  helpIcon={
                    <Tooltip
                      classNameConfig={{ content: 'max-w-[350px]' }}
                      content={t('tooltip:onboardDate')}>
                      <IconWrapper
                        size={14}
                        className="ml-[6px] text-gray-400"
                        name="HelpCircle"
                      />
                    </Tooltip>
                  }
                  label={`${t('placements:management:filter:start_date')}`}>
                  <Controller
                    control={control}
                    name="onboardDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <SingleDatePicker
                          locale={i18n.language}
                          size="sm"
                          destructive={!!formState.errors.onboardDate}
                          config={{
                            id: 1,
                            defaultOpen: false,
                            onChange,
                            value,
                            showClearIndicator: true,
                            onClear: () => {
                              onChange(undefined)
                            },
                            disabled: !!applicant?.createdAt
                              ? {
                                  before: changeTimezone({
                                    date: applicant.createdAt,
                                    timezone: user?.timezone
                                  })
                                }
                              : undefined
                          }}
                          placeholder={`${t('label:placeholder:selectDate')}`}
                        />
                      )
                    }}
                  />
                </FormControlItem>
              </div>
              <div className="flex-1">
                <FormControlItem
                  helpIcon={
                    <Tooltip
                      classNameConfig={{ content: 'max-w-[350px]' }}
                      content={t('tooltip:endOfProbation')}>
                      <IconWrapper
                        size={14}
                        className="ml-[6px] text-gray-400"
                        name="HelpCircle"
                      />
                    </Tooltip>
                  }
                  label={`${t('placements:management:table:end_date')}`}>
                  <Controller
                    control={control}
                    name="endOfProbationDate"
                    render={({ field: { onChange, value } }) => (
                      <SingleDatePicker
                        locale={i18n.language}
                        size="sm"
                        destructive={!!formState.errors.endOfProbationDate}
                        config={{
                          id: 2,
                          defaultOpen: false,
                          showClearIndicator: true,
                          disabled: !!onboardDateWatcher
                            ? {
                                before: onboardDateWatcher
                              }
                            : !!applicant?.createdAt
                            ? {
                                before: changeTimezone({
                                  date: new Date(),
                                  timezone: user?.timezone
                                })
                              }
                            : undefined,
                          onClear: () => {
                            onChange(undefined)
                          },
                          onChange,
                          value: value
                        }}
                        placeholder={`${t('label:placeholder:selectDate')}`}
                      />
                    )}
                  />
                </FormControlItem>
              </div>
            </div>
            <div className="mt-4 flex space-x-4">
              <div className="flex-1">
                <FormControlItem
                  label={`${t('placements:management:table:salary_USD')}`}>
                  <Controller
                    control={control}
                    name="salary"
                    render={({ field: { onChange, value } }) => (
                      <InputGroup
                        className="flex"
                        configPadding={{ left: '', right: '' }}>
                        <ComputeInputRightPadding>
                          {({
                            inputStyle,
                            rightInputRef,
                            createOnPaddingChange
                          }) => (
                            <>
                              <Input
                                size="sm"
                                autoFocus
                                style={inputStyle}
                                value={value}
                                onChange={onlyPositiveNumberChange(onChange)}
                                type="number"
                                placeholder="0"
                                className="hide-number-arrows"
                                step="any"
                                pattern="^\d+$"
                                destructive={!!formState.errors.salary}
                              />
                              <InputRightElement>
                                <Controller
                                  control={control}
                                  name="typeOfSalary"
                                  render={({ field: { onChange, value } }) => (
                                    <div ref={rightInputRef}>
                                      <NativeSelect
                                        size="sm"
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={createOnPaddingChange(
                                          (newValue) => {
                                            onChange(
                                              (newValue as ISelectOption).value
                                            )
                                          }
                                        )}
                                        value={typesOfSalary.find(
                                          (item: ISelectOption) =>
                                            item.value === value
                                        )}
                                        options={typesOfSalary}
                                        classNameOverride={{
                                          bordered: 'none',
                                          loadingMessage: `${t(
                                            'label:loading'
                                          )}`,
                                          noOptionsMessage: `${t(
                                            'label:noOptions'
                                          )}`
                                        }}
                                        menuPosition="fixed"
                                      />
                                    </div>
                                  )}
                                />
                              </InputRightElement>
                            </>
                          )}
                        </ComputeInputRightPadding>
                      </InputGroup>
                    )}
                  />
                </FormControlItem>
              </div>
              <div className="flex-1">
                <FormControlItem
                  destructive={!!formState.errors.fee}
                  destructiveText={formState.errors.fee?.message}
                  label={`${t('placements:management:table:fee')}`}>
                  <Controller
                    control={control}
                    name="typeOfFee"
                    render={({
                      field: {
                        onChange: onChangeTypeOfFee,
                        value: valueTypeOfFee
                      }
                    }) => (
                      <Controller
                        control={control}
                        name="fee"
                        render={({
                          field: { onChange: onChangeFee, value: fee }
                        }) => (
                          <InputGroup
                            className=""
                            configPadding={{ left: '', right: '' }}>
                            <ComputeInputRightPadding>
                              {({
                                inputStyle,
                                rightInputRef,
                                createOnPaddingChange
                              }) => (
                                <>
                                  <Input
                                    type="number"
                                    size="sm"
                                    autoFocus
                                    value={fee}
                                    pattern="^\d+$"
                                    style={inputStyle}
                                    className="hide-number-arrows"
                                    onChange={makeLimitChange(
                                      onChangeFee,
                                      { percentage: 2, flat: 2, months: 1 }[
                                        valueTypeOfFee?.toString() ||
                                          'percentage'
                                      ] || 2
                                    )}
                                    placeholder="0"
                                    step="any"
                                    destructive={!!formState.errors.fee}
                                    // onChangeEnter={() => handleSave({ autoSave: false })}
                                  />

                                  <InputRightElement>
                                    <div ref={rightInputRef}>
                                      <NativeSelect
                                        size="sm"
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={createOnPaddingChange(
                                          (newValue) => {
                                            onChangeTypeOfFee(
                                              (newValue as ISelectOption).value
                                            )
                                            onChangeFee('')
                                            // trigger('fee')
                                          }
                                        )}
                                        value={placementTypeOfFees.find(
                                          (item: ISelectOption) =>
                                            item.value === valueTypeOfFee
                                        )}
                                        options={placementTypeOfFees}
                                        classNameOverride={{
                                          bordered: 'none',
                                          loadingMessage: `${t(
                                            'label:loading'
                                          )}`,
                                          noOptionsMessage: `${t(
                                            'label:noOptions'
                                          )}`
                                        }}
                                        menuPosition="fixed"
                                      />
                                    </div>
                                  </InputRightElement>
                                </>
                              )}
                            </ComputeInputRightPadding>
                          </InputGroup>
                        )}
                      />
                    )}
                  />
                </FormControlItem>
              </div>
            </div>
            <div className="mt-4 flex">
              <div className="flex-1">
                <FormControlItem
                  label={`${t('placements:management:table:revenue')}`}>
                  <Controller
                    control={control}
                    name="revenue"
                    render={({ field: { onChange, value } }) => (
                      <InputGroup
                        className=""
                        configPadding={{ left: '', right: '' }}>
                        <ComputeInputRightPadding>
                          {({
                            inputStyle,
                            rightInputRef,
                            createOnPaddingChange
                          }) => (
                            <>
                              <Input
                                size="sm"
                                autoFocus
                                type="number"
                                style={inputStyle}
                                pattern="^\d+$"
                                value={value}
                                onChange={onlyPositiveNumberChange(onChange)}
                                step="any"
                                className="hide-number-arrows pr-[96px]"
                                destructive={!!formState.errors.revenue}
                                // onChangeEnter={() => handleSave({ autoSave: false })}
                              />
                              <InputRightElement>
                                <Controller
                                  control={control}
                                  name="currencyOfRevenue"
                                  render={({ field: { onChange, value } }) => (
                                    <div ref={rightInputRef}>
                                      <NativeSelect
                                        size="sm"
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={createOnPaddingChange(
                                          (newValue) => {
                                            onChange(
                                              (newValue as ISelectOption).value
                                            )
                                          }
                                        )}
                                        value={placementCurrencyOfRevenue.find(
                                          (item: ISelectOption) =>
                                            item.value === value
                                        )}
                                        options={placementCurrencyOfRevenue}
                                        classNameOverride={{
                                          bordered: 'none',
                                          loadingMessage: `${t(
                                            'label:loading'
                                          )}`,
                                          noOptionsMessage: `${t(
                                            'label:noOptions'
                                          )}`
                                        }}
                                        menuPosition="fixed"
                                      />
                                    </div>
                                  )}
                                />
                              </InputRightElement>
                            </>
                          )}
                        </ComputeInputRightPadding>
                      </InputGroup>
                    )}
                  />
                </FormControlItem>
              </div>
            </div>
            <div className="mt-4 flex">
              <div className="flex-1">
                <Controller
                  control={control}
                  name="profitSplits"
                  render={({
                    field: { onChange, value },
                    formState: { errors }
                  }) => {
                    const addNew = () => {
                      onChange([...value, {}])
                    }
                    const removeByIndex = (rindex: number) => {
                      if (value[rindex].id !== undefined) {
                        onChange(
                          value.map((item, mindex) =>
                            mindex === rindex
                              ? { ...item, _destroy: true }
                              : item
                          )
                        )
                      } else {
                        onChange(
                          value.filter((item, mindex) => mindex !== rindex)
                        )
                      }
                    }

                    return (
                      <div>
                        <div className="flex justify-between">
                          <div className="text-sm font-medium text-gray-700 dark:text-gray-300">
                            {t('placements:management:table:profitsSplit')}
                          </div>
                          <div>
                            <TextButton
                              onClick={(e) => {
                                e.preventDefault()
                                addNew()
                              }}
                              size="md"
                              icon="leading"
                              iconMenus="Plus"
                              label={`${t('button:addMember')}`}
                              underline={false}
                            />
                          </div>
                        </div>
                        <div>
                          {value.map((item, index) => {
                            const getValue = (key: string) =>
                              //@ts-ignore
                              value[index][key] || ''
                            const createOnChangeField =
                              //@ts-ignore
                              (key: string) => (newValue) => {
                                onChange(
                                  value.map((item, i) =>
                                    i === index
                                      ? { ...item, [key]: newValue }
                                      : item
                                  )
                                )
                              }
                            const getFieldError = (key: string) =>
                              //@ts-ignore
                              errors?.profitSplits?.[index]?.[key]
                            const allSelectedIds = value
                              .filter((item) => !item._destroy)
                              .map((item) => item.user_id?.value)
                            return !item._destroy ? (
                              <>
                                <div
                                  key={index}
                                  className="mt-4 flex items-start space-x-4 first:mt-2">
                                  <div className="flex-1">
                                    <AsyncSingleSearchWithSelect
                                      promiseOptions={(params) =>
                                        fetchHiringMembers(params).then(
                                          (rs) => ({
                                            ...rs,
                                            collection: rs.collection.filter(
                                              (item: { value: string }) =>
                                                !allSelectedIds.includes(
                                                  item.value
                                                )
                                            )
                                          })
                                        )
                                      }
                                      isSearchable
                                      isClearable={false}
                                      size="sm"
                                      onChange={createOnChangeField('user_id')}
                                      placeholder={`${t(
                                        'label:placeholder:select'
                                      )}`}
                                      configSelectOption={{
                                        supportingText: ['name', 'description'],
                                        avatar: true
                                      }}
                                      value={getValue('user_id')}
                                      destructive={getFieldError('user_id')}
                                      menuPlacement="top"
                                      classNameOverride={{
                                        loadingMessage: `${t('label:loading')}`,
                                        noOptionsMessage: `${t(
                                          'label:noOptions'
                                        )}`
                                      }}
                                    />
                                  </div>
                                  <div className="flex-1">
                                    <InputGroup
                                      className=""
                                      configPadding={{ left: '', right: '' }}>
                                      <Input
                                        size="sm"
                                        autoFocus
                                        className="pr-[37px]"
                                        value={getValue('profit_percentage')}
                                        onChange={createOnChangeField(
                                          'profit_percentage'
                                        )}
                                        destructive={
                                          !!getFieldError(
                                            'profit_percentage'
                                          ) || !!errors.profitSplits?.message
                                        }
                                        // onChangeEnter={() => handleSave({ autoSave: false })}
                                      />
                                      <InputRightElement className="flex w-[35px] items-center justify-center border-l border-gray-300">
                                        <IconWrapper size={12} name="Percent" />
                                      </InputRightElement>
                                    </InputGroup>
                                  </div>
                                  <div className="pt-1">
                                    <IconButton
                                      iconMenus="Trash2"
                                      onClick={() => removeByIndex(index)}
                                      size="xs"
                                      type="secondary-destructive"
                                      // iconMenus="Trash2"
                                    />
                                  </div>
                                </div>
                                <div className="mt-1 flex items-center space-x-4">
                                  <div className="w-[188px]">
                                    <FormControlItem
                                      destructive={getFieldError('user_id')}
                                      destructiveText={
                                        getFieldError('user_id')?.message
                                      }
                                    />
                                  </div>
                                  <div className="flex-1">
                                    <FormControlItem
                                      destructive={getFieldError(
                                        'profit_percentage'
                                      )}
                                      destructiveText={
                                        getFieldError('profit_percentage')
                                          ?.message
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            ) : null
                          })}
                        </div>
                        {!!errors.profitSplits?.message && (
                          <div className="mt-1 flex items-center space-x-4">
                            <div className="w-[188px]" />
                            <div className="flex-1">
                              <FormControlItem
                                destructive={!!errors.profitSplits?.message}
                                destructiveText={errors.profitSplits?.message}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  }}
                />
              </div>
            </div>
            {children}
          </>
        )
      }}
    </DynamicImportForm>
  )
}
export default PlacementForm
